import React, { useEffect, useState } from 'react'

import { Grid, Avatar, Typography, Badge, CircularProgress } from '@mui/material'
import styles from './index.module.scss'
import { ChannelI } from 'src/api/chat/types'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { chatOrCommentMessageType, pubnub } from 'src/utils/constants'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)
type RecentChatRowProps = {
  channel: ChannelI
  onClick: (channel: ChannelI) => void
  onContextMenu: (e: any, channel: ChannelI) => void
  activeChannelId: string
}
const RecentChatRow = ({
  channel,
  onClick,
  activeChannelId,
  onContextMenu,
}: RecentChatRowProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const [meta, setMeta] = useState({
    count: 0,
    lastMsg: '',
    lastSeen: '',
  })
  const [isLoading, setLoading] = useState(false)

  const timestamp: number = channel?.timestamp
    ? Number(channel.timestamp)
    : new Date().getTime() - 1000000

  const convertTimeToken = (timetoken: number) => {
    const datetime: number = new Date(Math.trunc(timetoken / 10000)).getTime()
    const day = dayjs(datetime)
    if (day.isToday()) {
      return day.format('hh:mm A')
    }
    return day.format('DD MMM')
  }

  const handleOpenChatClick = () => {
    setMeta({
      ...meta,
      count: 0,
    })
    onClick(channel)
  }

  useEffect(() => {
    let count = 0
    let lastMsg = ''
    let lastSeen = ''
    if (pubnub) {
      setLoading(true)
      pubnub
        .messageCounts({
          channels: [channel.channel_id],
          channelTimetokens: [timestamp],
        })
        .then((res: any) => {
          if (res?.channels?.[channel.channel_id]) {
            count = res.channels[channel.channel_id]
          }

          pubnub
            .fetchMessages({
              channels: [channel.channel_id],
              count: 1,
            })
            .then((response: any) => {
              if (response?.channels?.[channel.channel_id]) {
                const msgObj = response.channels[channel.channel_id][0]
                if (
                  msgObj.message.file &&
                  msgObj.message?.message?.title == chatOrCommentMessageType.audio
                ) {
                  lastMsg = 'Sent a voice'
                } else if (
                  msgObj.message.file &&
                  msgObj.message?.message?.title == chatOrCommentMessageType.image
                ) {
                  lastMsg = 'Sent an image'
                } else if (msgObj.message.title === chatOrCommentMessageType.text) {
                  // text as a message
                  lastMsg =
                    msgObj.message.description.length < 30
                      ? msgObj.message.description
                      : msgObj.message.description.substring(0, 30) + '...'
                } else {
                  // file as a message
                  lastMsg = 'Sent a message'
                }
                lastSeen = convertTimeToken(Number(msgObj.timetoken))
                setMeta({
                  count,
                  lastMsg,
                  lastSeen,
                })
              }
            })
            .finally(() => {
              setLoading(false)
            })
        })
        .catch((err: any) => {
          console.log('Error message count: ', err)
          setLoading(false)
        })
    }
  }, [])

  if (!userInfo) return null

  const toUser = channel.users.find((user) => user.id != userInfo.id)
  const fromUser = channel.users.find((user) => user.id == userInfo.id)

  if (channel.channel_type === 'single' && (!toUser || !fromUser)) return null

  const avatarImage =
    channel.channel_type === 'single' ? toUser?.profile_picture : channel.channel_icon
  const chatName =
    channel.channel_type === 'single'
      ? `${toUser?.first_name} ${toUser?.last_name}`
      : channel.channel_name

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      p={2}
      pl={5}
      pr={2}
      onClick={handleOpenChatClick}
      sx={{
        cursor: 'pointer',
        backgroundColor:
          activeChannelId === channel.channel_id ? 'rgba(52, 214, 149, 0.1)' : 'initial',
      }}
      onContextMenu={(e: any) => onContextMenu(e, channel)}
    >
      <Grid item>
        <Grid container>
          <Grid item>
            <Avatar
              alt={chatName || ''}
              src={avatarImage || 'broken-image.png'}
              className={styles.feedAvatar}
            />
          </Grid>
          <Grid item pl={1}>
            <Typography variant={'h5'}>{chatName}</Typography>
            <Typography variant='subtitle2' color='gray' fontWeight={400} fontSize={16}>
              {meta.lastMsg}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item textAlign='right'>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Typography variant='subtitle2' color='gray' fontWeight={400} fontSize={16}>
              {meta.lastSeen}
            </Typography>
            {!!meta.count && (
              <Badge badgeContent={meta.count} color='primary' sx={{ marginRight: '10px' }}></Badge>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default RecentChatRow
