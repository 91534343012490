import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import WaveSurfer from 'wavesurfer.js'
import { ErrorBoundary } from 'react-error-boundary'

import { Grid, IconButton, Typography, Popover, Box, Slider, CircularProgress } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import volumeIcon from 'src/assets/icons/volume.png'
// eslint-disable-next-line camelcase
import { secondsTo_ms_format } from 'src/utils/helpers'
const WaveformContianer = styled(Grid)`
  width: 100%;
  background: rgba(43, 45, 49, 1);
  border-radius: 30px;
  margin: 0px;
  padding: 8px;
`

const PlayButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  padding: 0px;
  background: #34d695;
  &:hover {
    background: #34d695;
  }
`

const ErrorComponent = () => {
  return (
    <WaveformContianer container alignItems='center' justifyContent='center' gap={2}>
      {' '}
      <Typography variant='subtitle1'>Unable to play audio</Typography>{' '}
    </WaveformContianer>
  )
}

type AudioPlayerProps = {
  url: string
  durationInSec: number
}
const AudioPlayer = ({ url, durationInSec }: AudioPlayerProps) => {
  if (!url || url.length === 0) {
    return <ErrorComponent />
  }

  const waveContainerId = 'waveform_' + Math.round(Math.random() * 1000)

  const waveform = useRef<WaveSurfer>()
  const durationRef = useRef<any>()
  const [duration, setDuration] = useState(isNaN(durationInSec) ? 0 : durationInSec)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [volume, setVolume] = React.useState<number>(50)
  const [error, setError] = useState<any>()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePlay = () => {
    if (waveform.current) {
      setIsPlaying(true)
      setVolume(waveform.current.getVolume() * 100)
      waveform.current.play()
      durationRef.current = setInterval(intervalFun, 1000)
    }
  }

  const handlePause = () => {
    if (waveform.current) {
      setIsPlaying(false)
      waveform.current.pause()
      clearInterval(durationRef.current)
    }
  }

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    setVolume(newValue as number)
    if (waveform.current) {
      waveform.current.setVolume((newValue as number) / 100)
    }
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const intervalFun = () => {
    // console.log(waveform)

    if (waveform.current) {
      const _duration = waveform.current.getDuration() - waveform.current.getCurrentTime()
      if (!isNaN(_duration)) {
        setDuration(_duration)
      }
    }

    setTimeout(() => {
      if (waveform.current && !waveform.current.isPlaying()) {
        setIsPlaying(false)
        clearInterval(durationRef.current)
      }
    }, 1000)
  }

  useEffect(() => {
    if (!waveform.current) {
      waveform.current = WaveSurfer.create({
        barWidth: 2,
        barRadius: 1,
        barGap: 4,
        cursorWidth: 1,
        container: '#' + waveContainerId,
        height: 32,
        progressColor: '#34d695',
        waveColor: '#B7B7B8',
        cursorColor: 'transparent',
        normalize: true,
      })

      setLoading(true)
      waveform.current
        .load(url)
        .then((res) => {
          if (waveform.current) {
            const _duration = waveform.current.getDuration() - waveform.current.getCurrentTime()
            if (!isNaN(_duration)) {
              setDuration(_duration)
            }
          }
        })
        .catch((err) => {
          setError(err)
          console.log('Error waveform: ', err)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return () => {
      if (waveform.current) {
        waveform.current.stop()
      }
      if (durationRef.current) {
        clearInterval(durationRef.current)
      }
    }
  }, [])

  const open = Boolean(anchorEl)

  if (error) {
    return <ErrorComponent />
  }

  const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }: any) => {
    return (
      <div>
        <h1>An error occurred: {error.message}</h1>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  return (
    <ErrorBoundary FallbackComponent={OurFallbackComponent}>
      <WaveformContianer container alignItems='center' gap={2}>
        <Grid>
          {isLoading ? (
            <CircularProgress size={30} />
          ) : (
            <PlayButton onClick={isPlaying ? handlePause : handlePlay}>
              {isPlaying ? (
                <PauseIcon
                  sx={{
                    color: 'white',
                  }}
                />
              ) : (
                <PlayArrowIcon
                  sx={{
                    color: 'white',
                  }}
                />
              )}
            </PlayButton>
          )}
        </Grid>

        <Grid flexGrow={2}>
          <div id={waveContainerId} />
        </Grid>

        <Grid>
          {' '}
          <Typography variant='subtitle2'> {secondsTo_ms_format(duration)} </Typography>{' '}
        </Grid>
        <Grid>
          <IconButton
            sx={{
              height: 32,
              width: 32,
            }}
            onClick={handlePopoverOpen}
          >
            <img src={volumeIcon} />
          </IconButton>
        </Grid>

        <Popover
          id='mouse-over-popover'
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          slotProps={{
            paper: {
              sx: {
                maxHeight: '500px',
                height: '260px',
                background: 'none',
                border: 'none',
                boxShadow: 'none',
              },
            },
          }}
          onClose={handlePopoverClose}
        >
          <Box
            sx={{
              height: '250px',
              padding: '15px',
              paddingTop: '20px',
              paddingBottom: '20px',
              background: 'rgba(43, 45, 49, 1)',
              borderRadius: '30px',
              border: '1px solid #B7B7B8',
            }}
          >
            <Slider
              step={10}
              marks
              min={0}
              max={100}
              orientation='vertical'
              aria-label='Volume'
              onChange={handleVolumeChange}
              value={volume}
            />
          </Box>
        </Popover>
      </WaveformContianer>
    </ErrorBoundary>
  )
}

export default AudioPlayer
